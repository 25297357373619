import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { KobrosLogo } from '@fingo/lib/assets';
import ProgressiveImageBox from '@fingo/lib/components/boxes/ProgressiveImageBox';
import { useIsMobile } from '@fingo/lib/hooks';
import QuickRegisterComponent from '../../../../components/pilots/components/QuickRegisterComponent';

const CollectionAccountCreationStep = ({ goNext }) => {
  const isMobile = useIsMobile();

  return (
    <Stack
      minHeight={{ xs: '100%', m: '90%' }}
      width="100%"
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'center', md: 'space-around' }}
      justifyContent={{ xs: 'center', md: 'space-around' }}
      pb={{ xs: 4, xl: 10 }}
      pt={2}
      px={{ xs: 4, md: 15, xl: 30 }}
      bgcolor={{
        xs: 'fingoWhite.main',
        md: 'inherit',
      }}
    >
      <Stack
        justifyContent="center"
        alignItems={{ xs: 'center', md: 'flex-start' }}
        spacing={2}
        width="auto"
        pb={{ xs: 0, md: 20 }}
        flex={{ xs: 0.35, md: 1 }}
      >
        <ProgressiveImageBox
          src={KobrosLogo}
          alt="KobrosLogo"
          sx={{
            maxHeight: 65,
            maxWidth: 'auto',
            objectFit: 'contain',
          }}
        />
        <Typography variant="h1" color="secondary">
          Crea tu cuenta en koBros
        </Typography>
        {!isMobile && (
          <>
            <Typography variant="body1" color="text">
              En koBros encontrarás tu mejor asesor de cobranza.
            </Typography>
            <Typography variant="body1" color="text">
              ¡Regístrate en 2 simples pasos!
            </Typography>
          </>
        )}
      </Stack>
      <Box flex={1} height="100%" width="100%" mt={{ xs: 2, md: 0 }}>
        <Paper
          elevation={4}
          sx={{
            height: '100%',
            py: { xs: 2, md: 3, lg: 5 },
            px: { xs: 3, md: 4, lg: 7 },
          }}
        >
          <QuickRegisterComponent
            onRegisterCompleted={goNext}
            origin="kobros"
          />
        </Paper>
      </Box>
    </Stack>
  );
};

CollectionAccountCreationStep.propTypes = {
  goNext: PropTypes.func.isRequired,
};

export default CollectionAccountCreationStep;
