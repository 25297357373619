const chileColumns = [
  'folio',
  'dteType_Code',
  'dateIssued',
  'receiver_sortable',
  'amountWithIva',
  'offerMonthlyRate',
  'offerDefaultRate',
  'offer_pendingDocuments',
  'offerTransferCondition',
  'dateToPay',
  'uploadDocuments',
];

const mexicoColumns = [
  'folio',
  'dteType_Code',
  'dateIssued',
  'receiver_sortable',
  'amountWithIva',
  'offerMonthlyRate',
  'offerDefaultRate',
  'dateToPay',
  'uploadDocuments',
];

const AvailableOffersColumnsByCountry = {
  Chile: chileColumns,
  Mexico: mexicoColumns,
};

export default AvailableOffersColumnsByCountry;
