import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  EXPORT_INVOICES,
  KOBROS_GET_MASTER_ENTITY,
  INVOICES_IN_COLLECTION,
} from '@fingo/lib/graphql';
import { DocumentList } from '@fingo/lib/components/lists';
import {
  ControlledSelectMenu,
} from '@fingo/lib/components/menus';
import RoundedWhiteBox from '@fingo/lib/components/boxes/RoundedWhiteBox';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTutorialHandler, useSelectedCompany } from '@fingo/lib/hooks';
import { InformativeHeader } from '@fingo/lib/components/headers';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  COLUMNS,
  PORTFOLIO_OPTIONS,
  CURRENT_PORTFOLIO_HEADERS,
  CURRENT_PORTFOLIO_INITIAL_ORDER_BY,
  HISTORIC_PORTFOLIO_HEADERS,
  HISTORIC_PORTFOLIO_INITIAL_ORDER_BY,
  INFORMATION_TOOLTIP_TEXT,
} from '@fingo/lib/constants/collection-summary';
import DebtorSummary from '../components/debtor/DebtorSummary';
import DebtorTourGuide from '../components/debtor/DebtorTourGuide';
import ClientConfigDialog from '../../../components/dialogs/ClientConfigDialog';

const Debtor = () => {
  const { debtorId } = useParams();
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [updateCustomVariables, setUpdateCustomVariables] = useState(true);
  const [portfolio, setPortfolio] = useState('Cartera vigente');
  const selectedCompany = useSelectedCompany();
  const [openDebtorConfig, setOpenDebtorConfig] = useState(false);
  const { data } = useQuery(KOBROS_GET_MASTER_ENTITY, {
    variables: { masterEntityId: debtorId,
      inCollection: selectedCompany.id },
    skip: !debtorId,
  });

  const TUTORIAL_CODE = 'kobros_debtor_guide';
  const [runGuide, toggleRunGuide] = useTutorialHandler(TUTORIAL_CODE);

  useEffect(() => {
    if (updateTrigger) {
      setSelectedDocumentIds([]);
      setUpdateTrigger(false);
    }
  }, [updateTrigger]);

  const getRowId = useCallback(({ id }) => id, []);
  const debtor = data?.getMasterEntity;
  const initialOrderBy = portfolio === 'Cartera vigente'
    ? CURRENT_PORTFOLIO_INITIAL_ORDER_BY
    : HISTORIC_PORTFOLIO_INITIAL_ORDER_BY;

  const headers = portfolio === 'Cartera vigente'
    ? CURRENT_PORTFOLIO_HEADERS
    : HISTORIC_PORTFOLIO_HEADERS;
  if (!debtor) return null;

  return (
    <>
      <Box height="100%" width="100%" p={2} backgroundColor="background.dark">
        <RoundedWhiteBox height="100%" width="100%">
          <DocumentList
            trackerId="COLLECTION_INVOICES"
            type="collection-debtor-profile"
            CustomHeader={InformativeHeader}
            customHeaderProps={{
              title: debtor.name,
              showGoBack: true,
              informativeText: INFORMATION_TOOLTIP_TEXT,
            }}
            queryDocument={INVOICES_IN_COLLECTION}
            checkboxSelection
            disabledCheckboxTooltip="No se puede gestionar ya que está financiada con Fingo"
            isRowSelectable={({ row }) => row.collectionManager?.owner.id === selectedCompany?.id}
            onSelectionModelChange={setSelectedDocumentIds}
            initialOrderBy={initialOrderBy}
            includeHeaders={headers}
            mobileHeaders={['folio', 'receiver_sortable', 'cessionStatus']}
            allCompaniesOptions
            getRowId={getRowId}
            updateTrigger={updateTrigger}
            setUpdateTrigger={setUpdateTrigger}
            showFilters
            exportType="collection"
            queryExport={EXPORT_INVOICES}
            showExportInvoice
            defaultFilterProps={{
              showValidSiiCredentialsFilter: false,
              showLightningFilter: false,
              showPreoffersFilters: false,
              showStatesFilter: false,
              showDatesFilter: false,
              showRefresh: true,
              showCompanyIssuerFilter: false,
            }}
            customVariables={{
              collectionManagerRelation_Status: portfolio === 'Cartera vigente' ? 'Pending collection' : 'Collected',
              debtorId: debtor.id,
              dateIssued_Gte: null,
              dateIssued_Lte: null,
              receiver_Rut: null,
              receiverId: null,
              companyId: selectedCompany?.id,
            }}
            customSummaryElement={(
              <DebtorSummary
                debtor={debtor}
                selectedDocumentIds={selectedDocumentIds}
                setSelectedDocumentIds={setSelectedDocumentIds}
                showActions={portfolio === 'Cartera vigente'}
              />
            )}
            flexEndButtons={() => (
              <>
                <ControlledSelectMenu
                  options={PORTFOLIO_OPTIONS}
                  value={portfolio}
                  setValue={setPortfolio}
                  onChange={() => {
                    setUpdateCustomVariables(true);
                    setSelectedDocumentIds([]);
                  }}
                />
                <Button
                  onClick={setOpenDebtorConfig}
                >
                  <SettingsIcon />
                </Button>
              </>
            )}
            initialPageSize={100}
            selectionModel={selectedDocumentIds}
            preColumns={COLUMNS}
            updateCustomVariables={updateCustomVariables}
            setUpdateCustomVariables={setUpdateCustomVariables}
          />
        </RoundedWhiteBox>
        <ClientConfigDialog
          open={openDebtorConfig}
          setOpen={setOpenDebtorConfig}
          title="Configuración de alertas"
          debtorId={debtorId}
          refetchQueries={() => setUpdateTrigger(true)}
        />
      </Box>
      <DebtorTourGuide run={runGuide} setRun={toggleRunGuide} />
    </>
  );
};

export default Debtor;
