import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { COLLECTION_INVOICES } from '@fingo/lib/graphql';
import { useSelectedCompany, useSendCollectionMailSteps } from '@fingo/lib/hooks';
import { MasterEntityType } from '@fingo/lib/propTypes';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import AddReactionOutlined from '@mui/icons-material/AddReactionOutlined';
import NoteAltOutlined from '@mui/icons-material/NoteAltOutlined';
import MailOutlined from '@mui/icons-material/MailOutlined';
import StepperDialog from '@fingo/lib/components/dialogs/StepperDialog';
import { CONTACT_COLUMNS } from '@fingo/lib/constants/collection-summary';
import { KobrosActionTutorial } from '../../../../components/buttons';

import {
  useAddCollectionActionSteps,
  useAddDataForCollectionSteps,
  useCollectSteps,
} from '../../hooks';
import {
  KobrosSendCollectionMail,
  KobrosAddDataForCollection,
  KobrosCollectInvoice,
  KobrosAddCollectionAction,
} from '../../../../components/typographys/kobros-action-texts';

const DebtorActions = ({ debtor, selectedDocumentIds, setSelectedDocumentIds }) => {
  const [openAddCollectionActionDialog, setOpenAddCollectionActionDialog] = useState(false);
  const [openSendCollectionMailDialog, setOpenSendCollectionMailDialog] = useState(false);
  const [openAddDataForCollectionDialog, setOpenAddDataForCollectionDialog] = useState(false);
  const [openCollectDialog, setOpenCollectDialog] = useState(false);
  const selectedCompany = useSelectedCompany();

  const { data, loading } = useQuery(COLLECTION_INVOICES, {
    variables: {
      id_In: selectedDocumentIds,
      first: selectedDocumentIds.length,
    },
    skip: !selectedDocumentIds.length || !selectedCompany.id,
  });

  const documents = Object.values(data || [])?.[0]?.edges?.map((doc) => doc?.node) || [];

  const [addCollectionActionSteps, addCollectionActionResetFields] = useAddCollectionActionSteps(
    debtor,
    documents,
  );
  const [addDataForCollectionSteps, addDataForCollectionResetFields] = useAddDataForCollectionSteps(
    debtor,
    documents,
  );
  const [sendCollectionMailSteps, sendCollectionMailResetFields] = useSendCollectionMailSteps(
    debtor,
    documents,
    false,
    true,
    CONTACT_COLUMNS,
  );
  const [collectSteps, collectResetFields] = useCollectSteps(documents);

  return (
    <Box>
      <Stack sx={{ borderRadius: 2, px: 1, width: 'auto', height: 100 }} id="debtor-actions" direction="column" spacing="auto">
        <Stack
          direction="row"
          width="100%"
          sx={{
            height: 20,
          }}
        >
          <Button
            id="add-collection-action-button"
            color="primary"
            variant="contained"
            size="small"
            backgroundColor="primary.main"
            startIcon={<AddReactionOutlined />}
            sx={{ width: '100%', justifyContent: 'flex-start', height: 0.6, py: 1.3 }}
            onClick={() => setOpenAddCollectionActionDialog(true)}
            disabled={selectedDocumentIds.length === 0 || loading}
          >
            <Typography
              noWrap
            >Nueva gestión
            </Typography>
          </Button>
          <KobrosActionTutorial video="kobros-add-collection-action">
            <KobrosAddCollectionAction />
          </KobrosActionTutorial>
        </Stack>
        <Stack
          direction="row"
          width="100%"
          sx={{
            height: 20,
          }}
        >
          <Button
            id="send-collection-mail-button"
            color="primary"
            size="small"
            variant="contained"
            startIcon={<MailOutlined />}
            sx={{ width: '100%', justifyContent: 'flex-start', height: 0.6, py: 1.3 }}
            onClick={() => setOpenSendCollectionMailDialog(true)}
            disabled={selectedDocumentIds.length === 0 || loading}
          >
            <Typography>Nuevo mail</Typography>
          </Button>
          <KobrosActionTutorial video="kobros-send-collection-mail">
            <KobrosSendCollectionMail />
          </KobrosActionTutorial>
        </Stack>
        <Stack
          direction="row"
          width="100%"
          sx={{
            height: 20,
          }}
        >
          <Button
            id="add-data-for-collection-button"
            backgroundColor="primary.main"
            variant="contained"
            size="small"
            sx={{ width: '100%', justifyContent: 'flex-start', height: 0.6, py: 1.3 }}
            startIcon={<NoteAltOutlined />}
            onClick={() => setOpenAddDataForCollectionDialog(true)}
            disabled={selectedDocumentIds.length === 0 || loading}
          >
            <Typography>Info recaudación</Typography>
          </Button>
          <KobrosActionTutorial video="kobros-add-data-for-collection">
            <KobrosAddDataForCollection />
          </KobrosActionTutorial>
        </Stack>
        <Stack
          direction="row"
          width="100%"
          sx={{
            height: 20,
          }}
        >
          <Button
            id="collect-button"
            backgroundColor="primary.main"
            variant="contained"
            size="small"
            sx={{ width: '100%', justifyContent: 'flex-start', height: 0.6, py: 1.3 }}
            startIcon={<NoteAltOutlined />}
            onClick={() => setOpenCollectDialog(true)}
            disabled={selectedDocumentIds.length === 0 || loading}
          >
            <Typography>Marcar como pagada</Typography>
          </Button>
          <KobrosActionTutorial
            video="kobros-collect-invoice"
          >
            <KobrosCollectInvoice />
          </KobrosActionTutorial>
        </Stack>
      </Stack>
      <StepperDialog
        open={openAddCollectionActionDialog}
        setOpen={setOpenAddCollectionActionDialog}
        handleClose={() => {
          addCollectionActionResetFields();
          setSelectedDocumentIds([]);
        }}
        steps={addCollectionActionSteps}
      />
      <StepperDialog
        open={openAddDataForCollectionDialog}
        setOpen={setOpenAddDataForCollectionDialog}
        handleClose={() => {
          addDataForCollectionResetFields();
          setSelectedDocumentIds([]);
        }}
        steps={addDataForCollectionSteps}
      />
      <StepperDialog
        open={openSendCollectionMailDialog}
        setOpen={setOpenSendCollectionMailDialog}
        handleClose={() => {
          sendCollectionMailResetFields();
          setSelectedDocumentIds([]);
        }}
        steps={sendCollectionMailSteps}
      />
      <StepperDialog
        open={openCollectDialog}
        setOpen={setOpenCollectDialog}
        handleClose={() => {
          collectResetFields();
          setSelectedDocumentIds([]);
        }}
        steps={collectSteps}
      />
    </Box>
  );
};

DebtorActions.propTypes = {
  debtor: MasterEntityType.isRequired,
  selectedDocumentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedDocumentIds: PropTypes.func.isRequired,
};

export default DebtorActions;
